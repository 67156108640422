.hidden {
  display: none !important;
}
.text-center {
  text-align: center;
  color: #fff;
}
.jinke-modal-mask {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: opacity 0.3s cubic-bezier(0.29, 0.32, 0.36, 0.78);
  pointer-events: none;
}
.jinke-modal-mask.modal-animate {
  -webkit-transform: scale(1);
          transform: scale(1);
  pointer-events: auto;
}
.jinke-modal-mask.modal-animate .jinke-modal {
  -webkit-animation: modalAnimate .35s ease forwards;
          animation: modalAnimate .35s ease forwards;
}
.jinke-modal-mask .jinke-modal {
  pointer-events: auto;
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 10px;
  min-width: 300px;
  min-height: 200px;
  max-width: 90%;
  max-height: 90%;
  z-index: 77;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
}
.jinke-modal-mask .jinke-modal .modal-header {
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid #d9d9d9;
  line-height: 40px;
  text-align: center;
  position: relative;
}
.jinke-modal-mask .jinke-modal .modal-header h2.title {
  font-size: 14px;
  color: #444;
  margin: 0;
}
.jinke-modal-mask .jinke-modal .modal-header .modal-close-btn {
  position: absolute;
  right: 15px;
  top: -2px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 100;
  color: #b1a4a4;
}
.jinke-modal-mask .jinke-modal .modal-header .modal-close-btn i {
  color: #333333;
}
.jinke-modal-mask .jinke-modal .modal-content {
  padding: 10px 20px;
  height: auto;
  overflow-y: auto;
  min-height: 100px;
  max-height: 90%;
}
.jinke-modal-mask .jinke-modal .modal-footer {
  padding: 10px;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.jinke-modal-mask .jinke-modal .modal-footer button {
  -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
}
.jinke-modal-mask .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 1.5;
  padding: 10px 30px;
  font-size: 12px;
  height: 38px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  border-radius: 5px;
  outline: none;
  color: #fff;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}
.jinke-modal-mask .btn:hover,
.jinke-modal-mask .btn:active {
  background-color: #e1e1e1;
}
.jinke-modal-mask .btn.btn-primary {
  color: #fff;
  background-color: #31c27c;
  border: 1px solid #31c27c;
}
.jinke-modal-mask .btn.btn-primary:hover,
.jinke-modal-mask .btn.btn-primary:active {
  background-color: #36cc84;
}
.jinke-modal-mask .btn.btn-default {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.jinke-modal-mask .btn.btn-default:hover,
.jinke-modal-mask .btn.btn-default:active {
  border: 1px solid #31c27c;
}
.jinke-modal-mask .btn.btn-warning {
  color: #fff;
  background-color: #ffbf00;
  border: 1px solid #ffbf00;
}
.jinke-modal-mask .btn.btn-warning:hover,
.jinke-modal-mask .btn.btn-warning:active {
  background-color: #ffc30f;
}
.jinke-modal-mask .btn.btn-error {
  color: #fff;
  background-color: #f04134;
  border: 1px solid #f04134;
}
.jinke-modal-mask .btn.btn-error:hover,
.jinke-modal-mask .btn.btn-error:active {
  background-color: #f14e42;
}
.jinke-modal-mask .btn.btn-success {
  color: #fff;
  background-color: #00a854;
  border: 1px solid #00a854;
}
.jinke-modal-mask .btn.btn-success:hover,
.jinke-modal-mask .btn.btn-success:active {
  background-color: #00b75c;
}
.jinke-modal-mask .btn.btn-info {
  color: #fff;
  background-color: #49a9ee;
  border: 1px solid #49a9ee;
}
.jinke-modal-mask .btn.btn-info:hover,
.jinke-modal-mask .btn.btn-info:active {
  background-color: #57b0ef;
}
.jinke-modal-mask .btn.btn-disabled {
  color: #fff;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  cursor: no-drop;
  border-style: dashed;
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}
.jinke-modal-mask .btn.btn-disabled:hover,
.jinke-modal-mask .btn.btn-disabled:active {
  background-color: #ffffff;
}
.jinke-modal-mask .btn.btn-block {
  width: 100%;
  display: block;
}
@-webkit-keyframes modalAnimate {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes modalAnimate {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}
